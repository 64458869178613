<template>
  <div class="recommend" v-if="!isLoading">
    <div v-if="recommendDetail.is_submit === 2">{{isChinese ? '该链接已失效' : 'The link has expired.'}}</div>
    <el-form label-position="right" label-width="260px" :model="ruleForm" ref="ruleFormRef" :rules="rules"  v-else>
      <el-form-item :label="isChinese ? '推荐人姓名:' : 'Name of the reference:'">
        <div class="name">{{isChinese ? recommendDetail.referrer_cn_name : recommendDetail.referrer_en_name}}</div>
      </el-form-item>
      <el-form-item :label="isChinese ? '被推荐人姓名:' : 'Name of the applicant:'">
        <div class="name">{{isChinese ? recommendDetail.apply_cn_name : recommendDetail.apply_en_name}}</div>
      </el-form-item>
      <el-form-item :label="isChinese ? '与被推荐人的关系:' : 'Relationship with you:'" :rules="[{required: true, message: 'This field cannot be left blank.'}]" prop="relation_with_applyer" class="relate">
        <el-input v-model="ruleForm.relation_with_applyer" />
        <el-popover
          placement="top-start"
          title=""
          :width="200"
          trigger="hover"
          content="Tips:Please also disclose your personal relationship with the applicant which may cause a conflict of interest, including but not limited to spouse, relative etc."
        >
          <template #reference>
           <el-icon :size="30"><info-filled /></el-icon>
          </template>
        </el-popover>
        <!-- <div class="tooltip-base-box">
          <el-tooltip
            class="box-item"
            effect="dark"
            placement="top"
          >
          <template #content>
            <div class="tips">Tips：please disclose your any other personal conflict of interest with the applicant which may include but not limited to spouse, relative etc.</div>
          </template>
            <el-icon :size="30"><info-filled /></el-icon>
          </el-tooltip>
        </div> -->
      </el-form-item>
      <el-form-item :label="isChinese ? '校验码:': 'Verification code:'" :rules="[{required: true, message: 'This field cannot be left blank.'}]" prop="check_code">
        <el-input v-model="ruleForm.check_code" />
      </el-form-item>
      <el-form-item :label="isChinese ? '推荐信语言:' : 'The language of reference letter:'" :rules="[{required: true, message: 'This field cannot be left blank.'}]" prop="lang">
        <el-radio-group v-model="ruleForm.lang" fill="#5EA6F8" text-color="#5EA6F8">
          <el-radio :label="1" >{{isChinese ?'中文' : 'Chinese'}}</el-radio>
          <el-radio :label="2" >{{isChinese ?'英文' : 'English'}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="isChinese ? '推荐信:': 'Reference letter:'" prop="reference_letter">
        <el-upload
          class="upload-demo"
          action="http-request"
          accept=".PDF"
          :before-upload="beforeAvatarUpload"
          :http-request="uploadFile"
          :on-remove="handleRemove"
          :on-change="handleChange"
          :show-file-list="false"
        >
          <!-- <template #trigger>
            <div class="up-box">
              <img src="@/assets/images/upload.png" alt="" class="upload-icon" />
              <div class="tips">上传附件，仅支持<span>PDF格式</span></div>
            </div>
          </template>
          <el-button class="ml-3" type="success" @click="submitUpload">upload to server</el-button> -->
          <div class="up-box">
            <img src="@/assets/images/upload.png" alt="" class="upload-icon" />
            <div class="tips" v-if="isChinese">上传附件，仅支持<span>PDF格式</span></div>
            <div class="tips" v-else>Upload the reference letter <span>in pdf format</span></div>
          </div>
        </el-upload>
         <div class="little-tips" slot="tip" v-if="tips">
              {{tips}}
          </div>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="submitForm(ruleFormRef)">{{isChinese ? '确认提交': 'Submit'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import { defineComponent, reactive, ref, onMounted, watch, computed } from 'vue'
  import { Ajax, MessageTips } from "@/utils";
  import * as qiniu from "qiniu-js";
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n'
  import { useStore } from "vuex";
  export default defineComponent({
    components: {},
    setup() {
      const { t } = useI18n()
      let store = useStore();
      let isChinese = ref(false);
      const language = computed(() => store.getters.language);
      watch(language, (newLang) => {
        // isChinese.value = newLang === "en" ? false : true;
        tips.value = isChinese.value ? `${recommendDetail.value.apply_cn_name}_推荐信_${recommendDetail.value.referrer_cn_name ? recommendDetail.value.referrer_cn_name : recommendDetail.value.referrer_en_name}.pdf` : 
          `${recommendDetail.value.apply_en_name}_Reference Letter_${recommendDetail.value.referrer_en_name}.pdf`
        // console.log('isChinese', isChinese.value)
      });
      
      const ruleFormRef = ref();
      const router = useRouter()
      const ruleForm = reactive({
        lang: 1,
        check_code: '',
        reference_letter: [],
        activation_code: router.currentRoute.value.query.activation_code
      })
      const recommendDetail = ref()
      const isLoading = ref(true)
      const upload = ref()
      const qiniuConfig = reactive({
        file_name: '', 
        token: '',
        config: {},
        link_host: ''
      })
      const tips = ref('')
      const checkReferenceLetter = (rule, value, callback) => {
          if (ruleForm.reference_letter.length < 1) {
              isChinese.value ? callback(new Error("不能为空！")): callback(new Error("This field cannot be left blank."))
          } else {
              callback()
          }
      }

      const rules = ref({
        reference_letter: [
          {
            required: true,
            trigger: "change",
            validator: checkReferenceLetter,
          },
        ],
      });
      watch(ruleForm, (val) => {
        if(val.reference_letter.length>0)
            ruleFormRef.value.validateField('reference_letter')
      });
      
      const handleExceed = (files) => {
        console.log('files', files)
        upload.value.clearFiles()
        upload.value.handleStart(files[0])
      }
      // const submitUpload = () => {
      //   upload.value.submit()
      // }
      const handleChange = (file, fileList) => {
        // console.log('file, fileList修改', file, fileList)
      }

      const handleRemove  = (file, fileList) => {
        ruleForm.reference_letter = []
      }

      const beforeAvatarUpload = async(file)=> {
        // console.log('file', file)
      const res = await Ajax.POST({
        url: '/api/uploadConfig',
        data: {
          business_code: 'reference_letter',
          file_name: file.name,
        }
      })
      if (res.data.code === 10000) {
        // console.log('qiniuConfig', qiniuConfig)
        Object.assign(qiniuConfig, res.data.data)
      }
      return true;
    }
    const uploadFile = (e) => {
      // console.log('e', e)
      const file = e.file;
      const putExtra = {
        fname: file.name, //文件原始文件名
      };
      const { file_name, token, config, link_host } = qiniuConfig;
      const observable = qiniu.upload(file, file_name, token, putExtra, config);
      observable.subscribe({
        next: (result) => {
          //  console.log('next', result)
        },
        error: (err) => {
          //  console.log('error', err)
        },
        complete: (result) => {
          // console.log('complete', result)
          const file_url = link_host + result.key;
          MessageTips.success("Uploaded successfully!");
          tips.value = isChinese.value ? `${recommendDetail.value.apply_cn_name}_推荐信_${recommendDetail.value.referrer_cn_name ? recommendDetail.value.referrer_cn_name : recommendDetail.value.referrer_en_name}.pdf` : 
          `${recommendDetail.value.apply_en_name}_Reference Letter_${recommendDetail.value.referrer_en_name}.pdf`
          ruleForm.reference_letter = file_url
        },
      });
    }

    
      //获取信息详情
    const getRecommendInformation = async() => {
      const res = await Ajax.POST({
        url: '/api/reference/getLetterDetail',
        data: {
          activation_code: ruleForm.activation_code
        }
      })
      if (res.data.code === 10000){
        recommendDetail.value = res.data.data
        isLoading.value = false
      }
    }

    // 提交推荐信信息
    const submitRecommend = async() => {
      const res = await Ajax.POST({
        url: '/api/reference/submitLetter',
        data: {
          ...ruleForm
        }
      })
      if (res.data.code === 10000){
        if(isChinese.value){
          MessageTips.success('提交成功！')
        } else {
          MessageTips.success('Saved Successfully!')
        }
         setTimeout(() => {
            sessionStorage.setItem('activeIndex',1);
            store.dispatch('app/setactiveIndex', 1);
            router.push('/index');
          }, 1000);
        getRecommendInformation()
      }
    }
    const submitForm = (formEl) => {
      // console.log('ruleForm', ruleForm)
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          // console.log("submit!");
          submitRecommend()
        } else {
          // console.log("error submit!");
          return false;
        }
      });
    };

    onMounted(() => {
      ruleForm.activation_code && getRecommendInformation()
    })
      return {
        t,
        ruleForm,
        ruleFormRef,
        submitForm,
        upload,
        handleExceed,
        // submitUpload,
        handleChange,
        beforeAvatarUpload,
        uploadFile,
        recommendDetail,
        isLoading,
        submitRecommend,
        isChinese,
        handleRemove,
        rules,
        tips
      }
    }
  })
</script>
<style lang="scss" scoped>
  .recommend {
    padding: 78px 0;
    display: flex;
    justify-content: center;
    width: 1200px;
    margin: 20px auto 70px;
    background: #fff;
    :deep(.el-form-item__label) {
      font-size: 15px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #262626;
    }
    :deep(.el-radio__input.is-checked .el-radio__inner) {
      border-color: #5ea6f8;
      background-color: #5ea6f8;
    }
    :deep(.el-radio__inner:hover) {
      border-color: #5ea6f8;
    }
    :deep(.el-radio__input.is-checked + .el-radio__label) {
      color: #333333;
    }
    .relate{
      :deep(.el-form-item__content){
        display: flex; 
        align-items: center;
      }
      .el-icon{
        margin-left: 10px;
      }
      .tooltip-base-box {
        // width: 600px;
        .box-item {
          // width: 110px;
          // margin-top: 10px;
        }
      }
    }
    .up-box {
      width: 256px;
      height: 125px;
      background: #fafafa;
      border-radius: 8px;
      border: 1px solid #e6e6e6;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      .tips {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #262626;
        margin-top: 19px;
        line-height: normal;
        span {
          color: #e13c39;
        }
      }
    }
     .little-tips{
        width: 256px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    :deep(.el-input__inner) {
      width: 350px;
      height: 34px;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
    }
    .el-button--primary {
      width: 150px;
      height: 50px;
      background: #e34c3e;
      border-radius: 4px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      border: none;
      margin-top: 45px;
      margin-left: 47px;
    }
  }
</style>
